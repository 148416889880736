<template>
  <section class="container pb-8 ">
    <div class="row">
      <div class="col-12 col-md-5 mx-auto">
        <h2 class="font-weight-bold mb-3 mb-md-6">會員登入</h2>
        <p v-if="false">
          <button type="button" class="btn btn-accent border-radius-m col-4" @click="loginFromLine">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="27"
            viewBox="0 0 27 27"
          >
            <g fill="none" fill-rule="evenodd">
              <g>
                <g>
                  <path
                    d="M0 0H35V35H0z"
                    transform="translate(-1197 -293) translate(1193 289)"
                  />
                  <path
                    fill="#FFF"
                    :d="lineIcon"
                    transform="translate(-1197 -293) translate(1193 289)"
                  />
                </g>
              </g>
            </g>
          </svg>
          <span class="small">使用Line登入</span>
          </button>
        </p>
        <p v-if="false" class="or">or</p>
        <el-form
          style="width: 100%;"
          :model="LoginForm"
          label-position="top"
          :rules="LoginRules"
          ref="LoginForm"
          class="cs-form login"
          data-style="2"
          @submit.prevent.native="Login()"
        >
          <div class="form-group mb-3">
            <el-form-item prop="loginId">
              <label class="text-muted" for="memberAccount"
                >帳號<span class="pl-1">Account</span></label
              >
              <el-input
                :class="`el-input__input`"
                placeholder="帳號"
                type="text"
                id="memberAccount"
                v-model="LoginForm.loginId"
              />
            </el-form-item>
          </div>
          <div class="form-group mb-6">
            <el-form-item prop="password">
              <label class="text-muted" for="memberPassword"
                >密碼<span class="pl-1">Password</span></label
              >
              <el-input
                :class="`el-input__input`"
                placeholder="密碼"
                type="password"
                id="memberPassword"
                v-model="LoginForm.password"
              />
            </el-form-item>
          </div>
          <div class="form-group mb-6">
            <el-form-item prop="captchaCode">
              <label class="sr-only" for="memberVerify">驗證碼</label>
              <div class="row">
                <div class="col-8 col-md-6 col-lg-8">
                  <el-input
                    :class="`el-input__input`"
                    placeholder="驗證碼"
                    type="text"
                    id="memberVerify"
                    v-model="LoginForm.captchaCode"
                  />
                </div>
                <div class="col-4 d-flex align-items-center pl-1">
                  <img
                    class="img-verify"
                    :src="`data:image/png;base64,${CodeImg}`"
                    alt="驗證碼"
                    @click="GetCaptchaCode"
                  />
                </div>
              </div>
            </el-form-item>
          </div>
          <div class="form-group mb-6">
            <div class="form-row justify-content-between mx-0">
              <div class="form-check">
                <input
                  class="form-check-input  border-accent"
                  type="checkbox"
                  id="keepMemberAccount"
                  v-model="keepMemberAccount"
                  :true-value="true"
                  :false-value="false"
                />
                <label class="form-check-label text-accent " for="keepMemberAccount"
                  >記住我的帳號
                </label>
              </div>
              <button type="submit" class="btn btn-accent btn-lg border-radius-m col-5">
                <span class="small">登入 Login</span>
              </button>
            </div>
          </div>
          <div class="form-group row justify-content-between pl-1 pr-1">
            <router-link class="link-hover-accent pl-4" to="/member/register">
              <span>註冊會員</span>
            </router-link>
            <router-link class="link-hover-accent pr-1" to="/forgotAccount">
              忘記帳號
            </router-link>
            <router-link class="link-hover-accent pr-4" to="/forgot">
              忘記密碼
            </router-link>
          </div>
        </el-form>
      </div>
    </div>
  </section>
</template>

<script>
import { Login, LineLogin } from '@/api/LoginApi';
import { GetCaptchaCode, GetCaptchaBase64 } from '@/api/CaptchaApi';

export default {
  data() {
    return {
      lineIcon:
        'M21.8 4.2c4.97 0 9 4.03 9 9v8.6c0 4.97-4.03 9-9 9h-8.6c-4.97 0-9-4.03-9-9v-8.6c0-4.97 4.03-9 9-9h8.6zm-4.65 3.527C11.555 7.727 7 11.464 7 16.057c0 4.115 3.612 7.562 8.488 8.217.33.07.78.221.895.505.101.258.067.655.032.924l-.139.872c-.038.257-.203 1.014.888.551 1.092-.46 5.85-3.487 7.98-5.965 1.459-1.615 2.156-3.27 2.156-5.103 0-4.594-4.555-8.331-10.15-8.331zm-5.991 5.905c.294 0 .533.244.533.54v3.54h1.485c.294 0 .532.243.532.54 0 .293-.238.537-.532.537H11.16c-.292 0-.533-.244-.533-.538v-4.08c0-.295.241-.539.533-.539zm3.573 0c.292 0 .53.244.53.54v4.08c0 .293-.238.537-.533.537-.292 0-.53-.244-.53-.538v-4.08c0-.295.238-.539.533-.539zm8.65 0c.293 0 .53.244.53.54 0 .297-.237.538-.532.538h-1.485v.962h1.485c.295 0 .532.244.532.54 0 .295-.237.538-.532.538h-1.485v.962h1.485c.295 0 .532.243.532.54 0 .293-.237.537-.532.537H21.36c-.291 0-.53-.244-.53-.538v-4.08c0-.295.239-.539.533-.539h2.018zm-3.796 0c.292 0 .533.244.533.54v4.08c0 .23-.147.435-.366.51-.054.017-.112.026-.168.026-.178 0-.33-.078-.431-.215l-2.067-2.836v2.513c0 .294-.236.538-.533.538-.293 0-.53-.244-.53-.538v-4.079c0-.231.147-.436.364-.509.05-.02.115-.028.164-.028.165 0 .317.09.419.218l2.082 2.847v-2.528c0-.295.239-.539.533-.539z',
      CodeImg: '',
      keepMemberAccount: false,
      LoginForm: {
        loginId: '',
        password: '',
        sourceTypeId: 1,
        code: '',
        captchaCode: '',
      },
      LoginRules: {
        loginId: [{ required: true, message: '請輸入帳號', trigger: 'blur' }],
        password: [{ required: true, message: '請輸入密碼', trigger: 'blur' }],
        captchaCode: [{ required: true, message: '請輸入驗證碼', trigger: 'blur' }],
      },
    };
  },
  created() {
    const myStorage = window.localStorage;
    // eslint-disable-next-line operator-linebreak
    this.LoginForm.loginId =
      myStorage.getItem('MemberAccount') !== null ? myStorage.getItem('MemberAccount') : '';
    this.keepMemberAccount = this.LoginForm.loginId !== '';

    this.GetCaptchaCode();
  },
  methods: {
    GetCaptchaCode() {
      // 取得驗證碼
      GetCaptchaCode().then((response) => {
        if (response.data.statusCode === 0) {
          this.LoginForm.code = response.data.data.code;
          GetCaptchaBase64(this.LoginForm.code).then((res) => {
            this.CodeImg = res.data;
          });
        }
      });
    },
    Login() {
      this.$refs.LoginForm.validate((valid) => {
        if (valid) {
          Login(this.LoginForm).then((response) => {
            if (response.data.statusCode === 0) {
              const myStorage = window.localStorage;
              if (this.keepMemberAccount) {
                myStorage.setItem('MemberAccount', this.LoginForm.loginId);
              } else {
                myStorage.setItem('MemberAccount', '');
              }

              const { jwtToken } = response.data.data;
              // Line先不上線
              // const { ExpTime, IsBinding } = JSON.parse(
              const { ExpTime } = JSON.parse(
                decodeURIComponent(
                  escape(
                    window.atob(
                      jwtToken
                        .split('.')[1]
                        .replace(/-/g, '+')
                        .replace(/_/g, '/'),
                    ),
                  ),
                ),
              );
              const d = this.$moment(ExpTime).add(30, 'days');
              const startDate = this.$moment();
              const endDate = this.$moment(d);
              const seconds = endDate.diff(startDate, 'seconds');
              document.cookie = `token=${jwtToken}; expires=${d}; max-age=${seconds}; path=/`;
              this.$message({
                showClose: true,
                type: 'success',
                message: response.data.message,
              });
              this.$setUserAccount();
              this.$bus.$emit('getShoppingCartCount');
              const { redirect } = this.$route.query;
              if (redirect) this.$router.replace(redirect);
              else this.$router.replace('/');
              // Line先不上線
              // if (!IsBinding) {
              //   this.$router.push('/member/info');
              // } else {
              //   const { redirect } = this.$route.query;
              //   if (redirect) this.$router.replace(redirect);
              //   else this.$router.replace('/');
              // }
            } else {
              this.GetCaptchaCode();
            }
          });
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: '請將資料填寫完整',
          });
        }
      });
    },
    loginFromLine() {
      LineLogin({
        socialNetwork: 1, // 1:LINE
        sourceTypeId: 1, // 1:前台 9:後台
      }).then((response) => {
        if (response.data.statusCode === 0) {
          // eslint-disable-next-line no-restricted-globals
          location.href = response.data.data;
        }
      });
    },
  },
};
</script>

<style>
.or {
  position: relative;
  text-align: center;
  margin: 0 0 15px 0;
}

.or:before, .or:after {
  position: absolute;
  content: "";
  width: calc(50% - 40px);
  border-top: 1px solid #3b3b3b;
  display: block;
  left: 0;
  top: 50%;
}

.or:after {
  right: 0;
  left: auto;
}
</style>
